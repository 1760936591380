/**
 * @author franckysolo
 *
 * @version 0.0.2
 */
class ErrorResponse {

  constructor (response) {
    this.response = response
  }
  
  message () {
    if (this.response.errors) {
      return this.errorMessage()
    } else {
      return this.singleMessage()
    }
  }
  
  singleMessage () {
    if (this.response.message) {
      return this.response.message
    }
  }
  
  errorMessage () {
    let message = ''
    for (const key in this.response.errors) {
      let error = this.response.errors[key]
      let msg = Array.isArray(error) 
        ? `<p>${error.pop()}</p>` 
        : `<p>${error}</p>`
      message += msg
    }
  
    return message
  }
}
  
export { ErrorResponse as default }