import * as types from './types'

const mutations = {
  [types.GET_ARTICLES] (state, items) {
    state.articles = items
  },
  [types.GET_ARTICLE] (state, item) {
    state.article = item
  },
  [types.GET_NEWS_LIST] (state, items) {
    state.newsList = items
  },
  [types.GET_NEWS] (state, item) {
    state.news = item
  },
  [types.GET_RESOURCES] (state, items) {
    state.resources = items
  },
  [types.GET_RESOURCE] (state, item) {
    state.resource = item
  },
  [types.GET_SERIES] (state, items) {
    state.series = items
  },
  [types.GET_SERIE] (state, item) {
    state.serie = item
  },
  [types.GET_MEETINGS] (state, items) {
    state.meetings = items
  },
  [types.GET_MEETING] (state, item) {
    state.meeting = item
  }
}

export default mutations
