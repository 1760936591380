// commons state
const state = {
  articles: [],
  article: {},
  meetings: [],
  meeting: {},
  newsList: [],
  news: {},
  resources: [],
  resource: {},
  series: [],
  serie: {}
}

export default state
