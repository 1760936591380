import { createStore, createLogger } from 'vuex'

// import commons & modules
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import auth from './modules/auth'

const strict = process.env.NODE_ENV !== 'production' ? true : false
const plugins = process.env.NODE_ENV !== 'production'
  ? [createLogger()]
  : []
/**
 * Create Application Store
 */
const store = createStore({
  state,
  actions,
  mutations,
  getters,
  modules: {
    auth
  },
  strict,
  plugins
})

export default store