// auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const GET_ARTICLES = 'GET_ARTICLES'
export const GET_ARTICLE = 'GET_ARTICLE'
export const GET_NEWS_LIST = 'GET_NEWS_LIST'
export const GET_NEWS = 'GET_NEWS'
export const GET_MEETING = 'GET_MEETING'
export const GET_MEETINGS = 'GET_MEETINGS'
export const GET_SERIE = 'GET_SERIE'
export const GET_SERIES = 'GET_SERIES'
export const GET_RESOURCE = 'GET_RESOURCE'
export const GET_RESOURCES = 'GET_RESOURCES'
