const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomePage'),
    meta: {
      title: 'Accueil',
      guest: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginPage'),
    meta: {
      title: 'Connexion',
      guest: true
    }
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import(/* webpackChunkName: "articles" */ '@/views/ListPage'),
    meta: {
      title: 'Les derniers articles',
      guest: false
    },
    props: {
      url: '/articles/',
      name: 'articles',
      label: 'Les articles LeMarson',
      action: 'getArticles'
    }
  },
  {
    path: '/articles/:id',
    name: 'article-detail',
    component: () => import(/* webpackChunkName: "article-detail" */ '@/views/DetailPage'),
    meta: {
      guest: false
    },
    props: {
      name: 'article',
      action: 'getArticle'
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '@/views/ListPage'),
    meta: {
      title: 'Les dernières news',
      guest: false
    },
    props: {
      url: '/news/',
      name: 'newsList',
      label: 'Les news LeMarson',
      action: 'getNewsList'
    }
  },
  {
    path: '/news/:id',
    name: 'news-detail',
    component: () => import(/* webpackChunkName: "news-detail" */ '@/views/DetailPage'),
    meta: {
      guest: false
    },
    props: {
      name: 'news',
      action: 'getNews'
    }
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "resources" */ '@/views/ListPage'),
    meta: {
      title: 'Les dernières ressources',
      guest: false
    },
    props: {
      url: '/resources/',
      name: 'resources',
      label: 'Les ressources LeMarson',
      action: 'getResources'
    }
  },
  {
    path: '/resources/:id',
    name: 'resource-detail',
    component: () => import(/* webpackChunkName: "resources-detail" */ '@/views/DetailPage'),
    meta: {
      guest: false
    },
    props: {
      name: 'resource',
      action: 'getResource'
    }
  },
  {
    path: '/formations',
    name: 'series',
    component: () => import(/* webpackChunkName: "series" */ '@/views/ListPage'),
    meta: {
      title: 'Les dernières formations',
      guest: false
    },
    props: {
      url: '/formations/',
      name: 'series',
      label: 'Les formations LeMarson',
      action: 'getSeries'
    }
  },
  {
    path: '/formations/:id',
    name: 'formation-detail',
    component: () => import(/* webpackChunkName: "formation-detail" */ '@/views/DetailPage'),
    meta: {
      guest: false
    },
    props: {
      name: 'serie',
      action: 'getSerie'
    }
  },
  {
    path: '/meetings',
    name: 'meetings',
    component: () => import(/* webpackChunkName: "meetings" */ '@/views/ListPage'),
    meta: {
      title: 'Les dernières meetings',
      guest: false
    },
    props: {
      url: '/meetings/',
      name: 'meetings',
      label: 'Les meetings LeMarson',
      action: 'getMeetings'
    }
  },
  {
    path: '/meetings/:id',
    name: 'meeting-detail',
    component: () => import(/* webpackChunkName: "meeting-detail" */ '@/views/DetailPage'),
    meta: {
      guest: false
    },
    props: {
      name: 'meeting',
      action: 'getSgetMeetingseries'
    }
  }
]

export default routes