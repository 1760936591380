// Commons actions
import axios from 'axios'
import * as types from './types'

const baseUrl = process.env.VUE_APP_URL

const actions = {
  /**
   * Stock les articles LeMarson dans le store
   *
   * @param  {Object}  store
   * @return {Boolean}
   */
  async getArticles (store, page) {
    const url = `${baseUrl}/articles?page=${page}`
    const result = await axios.get(url)
    if (result) {
      const articles = result.data
      store.commit(types.GET_ARTICLES, articles)
      return true
    }
    return false
  },

  /**
   * Stock un article LeMarson dans le store
   *
   * @return {Boolean}
   */
  async getArticle (store, id) {
    const url = `${baseUrl}/articles/${id}`
    const result = await axios.get(url)
    if (result) {
      const article = result.data.data
      store.commit(types.GET_ARTICLE, article)
      return true
    }
    return false
  },

  /**
   * Stock les articles LeMarson dans le store
   *
   * @param  {Object}  store
   * @return {Boolean}
   */
  async getNewsList (store, page) {
    const url = `${baseUrl}/news?page=${page}`
    const result = await axios.get(url)
    if (result) {
      const news = result.data
      store.commit(types.GET_NEWS_LIST, news)
      return true
    }
    return false
  },

  /**
   * Stock un article LeMarson dans le store
   *
   * @return {Boolean}
   */
  async getNews (store, id) {
    const url = `${baseUrl}/news/${id}`
    const result = await axios.get(url)
    if (result) {
      const news = result.data.data
      store.commit(types.GET_NEWS, news)
      return true
    }
    return false
  },

  /**
   * Stock les resources LeMarson dans le store
   *
   * @param  {Object}  store
   * @return {Boolean}
   */
  async getResources (store, page) {
    const url = `${baseUrl}/resources?page=${page}`
    const result = await axios.get(url)
    if (result) {
      const resources = result.data
      store.commit(types.GET_RESOURCES, resources)
      return true
    }
    return false
  },

  /**
   * Stock une resource LeMarson dans le store
   *
   * @return {Boolean}
   */
  async getResource (store, id) {
    const url = `${baseUrl}/resources/${id}`
    const result = await axios.get(url)
    if (result) {
      const resource = result.data.data
      store.commit(types.GET_RESOURCE, resource)
      return true
    }
    return false
  },

  /**
   * Stock les meetings LeMarson dans le store
   *
   * @param  {Object}  store
   * @return {Boolean}
   */
  async getMeetings (store, page) {
    const url = `${baseUrl}/meetings?page=${page}`
    const result = await axios.get(url)
    if (result) {
      const meetings = result.data
      store.commit(types.GET_MEETINGS, meetings)
      return true
    }
    return false
  },

  /**
   * Stock un meeting LeMarson dans le store
   *
   * @return {Boolean}
   */
  async getMeeting (store, id) {
    const url = `${baseUrl}/meetings/${id}`
    const result = await axios.get(url)
    if (result) {
      const meeting = result.data.data
      store.commit(types.GET_MEETING, meeting)
      return true
    }
    return false
  },

  /**
   * Stock les meetings LeMarson dans le store
   *
   * @param  {Object}  store
   * @return {Boolean}
   */
  async getSeries (store, page) {
    const url = `${baseUrl}/series?page=${page}`
    const result = await axios.get(url)
    if (result) {
      const series = result.data
      store.commit(types.GET_SERIES, series)
      return true
    }
    return false
  },

  /**
   * Stock une formation LeMarson dans le store
   *
   * @return {Boolean}
   */
  async getSerie (store, id) {
    const url = `${baseUrl}/series/${id}`
    const result = await axios.get(url)
    if (result) {
      const serie = result.data.data
      store.commit(types.GET_SERIE, serie)
      return true
    }
    return false
  }
}
export default actions
