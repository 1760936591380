import axios from 'axios'
import * as types from '../types'
import FormError from '@/classes/form-error'

const baseUrl = process.env.VUE_APP_URL

// action access by ...mapStates
const state = {
  token: localStorage.getItem('token') || ''
}

const actions = {
  /**
   * Login user
   *
   * @param {Object} store Vuex store
   * @param {Object} credentials object credentials
   * @return {Boolean} true if auth success false if not
   */
  async login (store, credentials) {
    let url = `${baseUrl}/connect`
    let result = null
    try {
      result = await axios.post(url, credentials)
      if (result) {
        const token = result.data.token
        localStorage.setItem('token', token)
        store.commit(types.LOGIN_SUCCESS, result.data)
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        return true
      }
      store.commit(types.LOGIN_FAILURE, result.data.message)
      return false
    } catch (e) {
      let f
      if (e.response && e.response.data) {
        f  = new FormError(e.response.data)
      } else {
        f  = new FormError(e)
      }

      store.commit(types.LOGIN_FAILURE, f.message())
      return false
    } finally {
      result = null
    }
  }
}

// action access by ...mapGetters
const getters = {
  token: state => state.token,
  isAuthenticate: state => state.token !== ''
}

const mutations = {
  [types.LOGIN_SUCCESS] (state, data) {
    state.token = data.api_key
  },
  [types.LOGIN_FAILURE] (state) {
    state.token = null
  },
  [types.LOGOUT_SUCCESS] (state) {
    state.token = null
  },
  [types.LOGOUT_FAILURE] (state) {
    state.token = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}