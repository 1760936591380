/**
 * Configuration global validators vee-validate
 */
import { configure, defineRule } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
import fr from '@vee-validate/i18n/dist/locale/fr.json'
import rules from '@vee-validate/rules'
 
Object.keys(rules).forEach(rule => {
  defineRule(rule, rules[rule])
})

configure({
  generateMessage: localize({
    en,
    fr
  })
})
 