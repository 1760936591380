// commons getters
const getters = {
  articles: state => state.articles,
  article: state => state.article,
  meetings: state => state.meetings,
  meeting: state => state.meeting,
  newsList: state => state.newsList,
  news: state => state.news,
  resources: state => state.resources,
  resource: state => state.resource,
  series: state => state.series,
  serie: state => state.serie
}

export default getters
